window.Posts = new (function () {

    const Swal = require('./plugins/sweetalert2');

    var that = this;
    var inputProductBannerMobile = $('#banner_mobile');
    var inputProductBannerDesk = $('#banner_desktop');
    var inputLogoImg = $('#logo_img');
    var inputBlock1Img = $('#block_1_img');
    var inputBlockCentralImg = $('#block_central_img');
    var inputBlock2Img = $('#block_2_img');

    var onDragEnter = function (event) {
            event.preventDefault();
            $(this).addClass("dragover");
        },

        onDragOver = function (event) {
            event.preventDefault();
            if (!$(this).hasClass("dragover"))
                $(this).addClass("dragover");
        },

        onDragLeave = function (event) {
            event.preventDefault();
            $(this).removeClass("dragover");
        },

        onDrop = function (event) {
            event.preventDefault();
            $(this).removeClass("dragover");

            var device = $(this).data('device');
            var files = event.originalEvent.dataTransfer.files;

            that.bannerPreview(files, device);

            if (device == 'desk') {
                device = 'banner_desktop';
            }
            if (device == 'mobile') {
                device = 'banner_mobile';
            }

            $('#' + device).prop("files", files);
        };

    this.init = function () {

        //active dropzone for banners
        $('.dropZone').on("dragenter", onDragEnter)
            .on("dragover", onDragOver)
            .on("dragleave", onDragLeave)
            .on("drop", onDrop);

        //$('.box').on('click', that.callInputFile);

        $(inputProductBannerMobile, inputProductBannerDesk, inputLogoImg, inputBlock1Img, inputBlockCentralImg, inputBlock2Img).on('change', function (event) {
            var device = $(this).data('device');
            var files = event.target.files;
            that.bannerPreview(files, device);
        });

        //change to visible or invisible banner
        $('.disable-post').on('click', that.changeVisible);

        try {
            $('#sortable').sortable();
            $('#sortable').disableSelection();
            $("#sortable").on("sortstop", that.updatePositions);
        } catch (e) {

        }

        //Open services
        $('.open-services').on('click', that.openPostServices);
        //select the product feature
        $(document).on('click', '.select-service-post', that.markSelection);
        $(document).on('click', '.saveSelectionServices', that.saveMarkSelection);

        $('.wNormalizeText').on('keyup', that.normalizeText);

    };

    /**
     * Load image preview
     * @param files
     * @param device
     */
    this.bannerPreview = function (files, device) {

        var elementID = 'bannerDesk';

        if (device == 'mobile') {
            elementID = 'bannerMobile';
        } else if (device == 'logo_img') {
            elementID = 'logoImg';
        } else if (device == 'block_1_img') {
            elementID = 'Block1Img';
        } else if (device == 'block_central_img') {
            elementID = 'BlockCentralImg';
        } else if (device == 'block_2_img') {
            elementID = 'Block2Img';
        }

        //file exist
        if (files && files[0]) {
            var reader = new FileReader();

            //load src
            reader.onload = function (e) {
                $('#' + elementID).attr('src', e.target.result);
                $('#' + elementID).removeClass('d-none');

                $('.box.' + device).addClass('loaded');
            }

            reader.readAsDataURL(files[0]); // convert to base64 string

            //pass to input file
            //(device == 'mobile') ? $(inputProductBannerMobile).val(files[0]) : $(inputProductBannerDesk).val(files[0]);
        }

    };

    /**
     * Trigger to input file
     */
    this.callInputFile = function () {

        var device = $(this).data('device');

        if (device == 'mobile') {
            $(inputProductBannerMobile).trigger('click');
        } else if (device == 'desk') {
            $(inputProductBannerDesk).trigger('click');
        } else if (device == 'logo_img') {
            $(inputLogoImg).trigger('click');
        } else if (device == 'block_1_img') {
            $(inputBlock1Img).trigger('click');
        } else if (device == 'block_2_img') {
            $(inputBlock2Img).trigger('click');
        }
    };

    /**
     * change product visible / invisible
     */
    this.changeVisible = function () {

        //wait alert
        Swal.fire({
            title: 'Por favor, espere!',
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        var postID = $(this).data('postid');
        var theButton = $(this);

        //ajax settings
        var settings = {
            url: changePostVisibleRoute,
            method: "POST",
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'post_id': postID,
            }
        }

        //ajax call
        $.ajax(settings).done(function (response) {
            var message = 'Post cambió a visible en el sitio web.';
            $(theButton).html('Ocultar');
            $('.post-' + postID).removeClass('no-visible');
            $('.btnStd-' + postID).removeClass('no-visible');

            try {
                $(theButton).tooltip('hide')
                    .attr('data-original-title', 'Clic para ocultar este post')
                    .tooltip('fixTitle')
                    .tooltip('show');
            } catch (e) {

            }


            if (response == 'invisible') {
                message = 'Post cambió a no visible en el sitio web.';
                $(theButton).html('Hacer visible');
                $('.post-' + postID).addClass('no-visible');
                $('.btnStd-' + postID).addClass('no-visible');
                try {
                    $(theButton).tooltip('hide')
                        .attr('data-original-title', 'Clic para hacer visible este post')
                        .tooltip('fixTitle')
                        .tooltip('show');
                } catch (e) {

                }
            }

            //ajax call fail
            Swal.fire(
                'Cambio exitoso',
                message,
                'success'
            );


        }).fail(function (xhr, status, error) {

            //ajax call fail
            Swal.fire(
                'Ocurrio un error',
                error,
                'error'
            );
        });

    };


    /**
     * post services
     */
    this.openPostServices = function () {

        var postID = $(this).data('id');
        var postServicesHTML = that.composeServiceHTML(postID);

        Swal.fire({
            title: 'Servicios',
            html: postServicesHTML,
            showConfirmButton: false,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            customClass: 'postServices-list'
        });

    };

    /**
     * product feature
     * html
     * @param postID
     */
    this.composeServiceHTML = function (postID) {
        var html = "";
        html += '<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">';


        $.each(postServices, function (index, service) {
            var autoSelected = '';

            $.each(service.post_service, function (i, postService) {
                if (service.id == postService.service_id && postID == postService.post_id) {
                    autoSelected = 'selected';
                }
            });

            html += '<div class="wrap-post select-service-post small ' + autoSelected + '" data-type="post" data-postid="' + postID + '" data-id="' + service.id + '">';
            html += '<label>';
            html += service.name;
            html += '</label>';
            html += '<i class="icon fas fa-check"></i>';
            html += '</div>';

        });

        html += '<div class="wrap-footer">';
        html += '<button class="saveSelectionServices cmaBtn cmaBtn-lined-secondary w-50" style="min-width: 100px; border-radius: 10px;" data-form="select-post-service">Guardar selección</button>';
        html += '</div>';
        html += '</div>';

        return html;
    };

    /**
     * mark the product feature or ingredient
     */
    this.markSelection = function () {

        $(this).toggleClass('selected');

    };

    /**
     * Save Features
     */
    this.saveMarkSelection = function () {
        var elements = $(document).find('.select-service-post.selected');
        var idsArray = [];
        var postID = 0;

        //show wait
        Swal.fire({
            title: 'Procesando',
            text: 'Por favor espera, guardando datos',
            showCloseButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        $.each(elements, function (index, element) {

            var theID = $(element).data('id');
            postID = $(element).data('postid');

            idsArray.push(theID);

        });

        //send data
        $.ajax({
            type: 'POST',
            url: saveServicesSelectedRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'post_id': postID,
                'data': idsArray,
            },
            success: function (response) {

                postServices = $.parseJSON(response);

                Swal.fire(
                    'Ok!',
                    'Selección guardada',
                    'success'
                )
            }
        });

    };


    /**
     * Made slug
     * @param str
     */
    this.toSlug = function (str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        var to = "aaaaeeeeiiiioooouuuunc------";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    };

    /**
     * Normalize text
     */
    this.normalizeText = function () {

        var theText = that.toSlug($(this).val());
        $(this).val(theText);

    };


})();

