/**
 *
 * Main class
 *
 */

window.Main = new (function () {

    var that = this;

    const Swal = require('./plugins/sweetalert2');

    /**
     * Init
     */
    this.init = function () {

        //input file named
        $('input[type="file"]').change(that.fileInput);

        //delete element
        $('.delete-element').on('click', that.confirmDelete);

        //just numeric validation field
        $(document).on('keyup', '.justnumeric', that.justNumeric);

        //Tooltips
        $('.withTooltip').tooltip();

        //Report no dealer to admin
        $('.report-no-dealer').on('click', that.reportNoDealer);

        //Tooltips
        $('[data-toggle="tooltip"]').tooltip();

        //Show tooltip info
        $('.show-tooltip-info').on('click', that.showToolTilInfo);

    }; //init


    /**
     * Custom file input
     */
    this.fileInput = function () {

        var theInput = $(this);
        if (theInput.is('.input-filepiker')) return;
        var theLabel = $(theInput).next();
        if (theInput[0].files[0]) {
            $(theLabel).text(theInput[0].files[0].name);
        }


    }; //init

    /**
     * Show Confirm dialog
     */
    that.confirmDelete = function () {
        var elementID = $(this).data('id');
        var name = $(this).data('elementname');

        Swal.fire({
            title: '¿Desea eliminar ' +  name + '?',
            text: 'Una vez realizada la acción ya no se podrá recuperar el registro',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ffd500',
            cancelButtonColor: '#1c1c1c',
            confirmButtonText: 'Si, eliminar'
        }).then((result) => {

            if (result.value) {
                $('#delete-form-' + elementID).submit();
            }
        })

    };

    /*
     * Show Alert
     * @param title
     * @param description
     * @param typeAlert
     */
    this.showAlert = function (title, description, typeAlert) {

        Swal.fire(title, description, typeAlert);

    }; //showErrorAlert

    /**
     * Validate field, just numeric
     */
    this.justNumeric = function (e) {
        $(this).val($(this).val().replace(/[^0-9\.]/g, '').replace(/[\.\^\'\!]/g, ''));
    }; //justNumeric

    /**
     * Wait alert
     */
    this.showWaitSwal = function () {
        Swal.fire({
            title: waitText,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });
    };

    /**
     * Report no dealer section to admin
     */
    this.reportNoDealer = function () {
        that.showWaitSwal();

        var section = $(this).data('section');

        $.ajax({
            type: 'POST',
            url: reportNoDealerRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'section': section,
                'country': $(this).data('country'),
            },
            success: function (response) {
                that.showAlert(noDealerTitle, noDealerDescription, 'success');
            }
        });
    };

    /**
     * Show tooltip info
     * (text info)
     */
    this.showToolTilInfo = function () {
        var text = $(this).data('text');

        that.showAlert('', text, 'info');

    };

})();

$(function () {
    var group = $(".list").sortable({
        group: 'serialization',
        delay: 500,
        onDrop: function ($item, container, _super) {
            let data = group.sortable("serialize").get();
            let SerializeData = JSON.stringify(data, null, ' ');
            let Data = JSON.parse(SerializeData);
            let Id = $(".GalleryBody").data("idpreowned");
            $.ajax({
                data: {gallery: Data},
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-CSRF-Token', Token)
                },
                type: 'POST',
                url: '/admin/preowned/gallery/order/update/' + Id,
            });
            _super($item, container);
        }
    });
});
